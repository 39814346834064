import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import Web3 from 'web3'
import './Faucet.css'
import info_icon from './images/info_icon.svg'
import logo from './images/sakura_logo.svg'

const config = require('./config/global.json');
const SITE_KEY = config.faucet.sliteKey
const DELAY = config.faucet.delay
const errorMessageMap = {
 'err1': 'You can request 10 Testnet SKUs per address per day.',
 'err2': 'You can request up to 100 Testnet SKUs per IP address per day.',
 'err3': 'Your verification code has expired, please re-try!',
 'err4': 'Some errors have occurred.',
}

class Faucet extends React.Component {

 constructor(props) {
  super(props)
  this.state = {
   callback: 'not fired',
   value: '',
   load: false,
   expired: false,
   address: '',
   sentCount: 0,
   errorMsg: '',
   servedToken: 0,
   isRequesting: false,
  }
  this._reCaptchaRef = React.createRef()
 }

 componentDidMount() {
  setTimeout(() => {
   this.setState({ load: true })
  }, DELAY)
  this.getServedToken()
 }

 getServedToken = async () => {
  // load bottom tip from 0x9B2...47f6 `count`
  const response = await fetch('/api/getServedToken', {
   method: 'POST',
   headers: {
    'Content-Type': 'application/json',
   },
  })

  const resJson = await response.text()
  const res = resJson ? JSON.parse(resJson) : {}
  if (res.status === 'ok') {
   const servedToken = res.data
   this.setState({ servedToken })
  }
 }

 handleChange = value => {
  this.setState({ value })
  // if value is null recaptcha expired
  if (value === null) this.setState({ expired: true })
 }

 asyncScriptOnLoad = () => {
  this.setState({ callback: 'called!' })
 }

 updateAddress = event => {
  const address = event.target.value
  this.setState({ address })
 }

 onRequestTestnetSKU = async () => {
  const isAddress = Web3.utils.isAddress(this.state.address)
  if (!isAddress) {
   this.setState({ errorMsg: 'Invalid wallet address!' })
   return
  }
  const params = {
   'addr': this.state.address,
   'captcha_token': this.state.value,
  }
  this.setState({ isRequesting: true })
  const response = await fetch('/api/getEthToken', {
   method: 'POST',
   body: JSON.stringify(params),
   headers: {
    'Content-Type': 'application/json',
   },
  })

  const resJson = await response.text()
  const res = resJson ? JSON.parse(resJson) : {}
  // reset recaptcha
  this._reCaptchaRef.current.reset()
  // send success
  if (response.status === 200 && res.status === 'ok') {
   const sentCount = parseInt(res.value)
   this.setState({ sentCount, errorMsg: '', isRequesting: false, value: '' })
   // refresh bottom sent count
   this.getServedToken()
   return
  }
  // send has error
  const errorCode = res.data
  let errorMsg = errorMessageMap[errorCode]
  if (!errorMsg) {
   errorMsg = 'Testnet SKUs not sent.'
  }
  this.setState({ errorMsg, isRequesting: false, value: '' })
 }

 render() {
  const { address, value, load, expired, sentCount, errorMsg, servedToken, isRequesting } = this.state || {}
  return (
   <div className='Faucet'>
    <div className='Faucet-header'>
     <img src={logo} className='Faucet-logo' alt='logo' />
    </div>
    <div className='Faucet-body'>
     <div className='Card-panel'>
      <span className='Panel-title'>Sakura Testnet Faucet</span>
      <span className='Panel-subtitle'>Feel free to get test SKUs to your wallet</span>
      <input
       className='Panel-input'
       type='text'
       onChange={this.updateAddress}
       placeholder='Input your testnet SKU address'
       id='address'>
      </input>
      <div className='Panel-recaptcha'>
       {load && (
        <ReCAPTCHA
         ref={this._reCaptchaRef}
         sitekey={SITE_KEY}
         onChange={this.handleChange}
         asyncScriptOnLoad={this.asyncScriptOnLoad}
        />
       )}
      </div>
      <button
       className='Panel-button'
       disabled={(address?.length === 0) || (value?.length === 0) || expired || isRequesting}
       onClick={this.onRequestTestnetSKU}>
       {isRequesting ? 'Requesting SKU' : 'Request Testnet SKU'}
      </button>
      {sentCount === 0 && (errorMsg.length <= 0) && <div className='Panel-tip'>
       <img src={info_icon} className='Tip-icon' alt='info' />
       <span className='Tip-content'>You can request 10 Testnet SKUs once per address per day.</span>
      </div>}
      {sentCount !== 0 && (errorMsg.length <= 0) && <div className='Panel-tip'>
       <span className='Tip-sent'>
        {sentCount} Testnet SKUs successfully sent to {address} Click
        <a href={`https://test-scan.sakurafinance.io/address/${address}/transactions`} target="_blank" rel="noopener noreferrer">&nbsp;here&nbsp;</a>
        to view the transaction.
       </span>
      </div>}
      {errorMsg.length > 0 && <div className='Panel-tip'>
       <span className='Tip-error'>{errorMsg}</span>
      </div>}
     </div>
    </div>
    <div className='Faucet-footer'>
     <span className='Footer-tip'>
      Testnet SKUs are served from
      <br></br>
      <span className='Highlight-tip'>0x9B2...47f6</span>
      &nbsp;&nbsp;with&nbsp;&nbsp;
      <span className='Highlight-tip'>{servedToken}&nbsp;&nbsp;</span>
      Testnet SKUs.
     </span>
    </div>
   </div>
  )
 }
}
export default Faucet